import React from "react";
import Roots from "./routes/Routes";
import { Toaster } from "react-hot-toast";

function App() {
  console.log("==================App==============");

  return (
    <>
      <div>
        <Roots />
        <Toaster
          position="top-center"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            // Define default options
            className: "",
            duration: 3000,
            style: {
              background: "#363636",
              color: "#fff",
            },

            // Default options for specific types
            success: {
              duration: 3000,
              theme: {
                primary: "green",
                secondary: "black",
              },
            },
          }}
        />
      </div>
    </>
  );
}

export default App;
