import { useTrouverMaFormationeContexte } from "context/TrouverMaFormationeContexteProvider";
import React from "react"
import { TfiBag } from "react-icons/tfi";
import TrouverFormationCard from "./TrouverFormationCard";
import { useLocation, useNavigate } from "react-router-dom";
import { TROUVER_FORMATION_PATH } from "routes/navigation/navigationPaths";

const DomaineCardItem = ({ domaine }) => {
  const { trouverFormation, setDomaineChoice } = useTrouverMaFormationeContexte();
    const navigate = useNavigate();
    const location = useLocation();

    const handleDomaineClick = () => {
        if (location.pathname === TROUVER_FORMATION_PATH) {
            setDomaineChoice(domaine);
        } else {
            setDomaineChoice(domaine);
            navigate(TROUVER_FORMATION_PATH + "?domaine=" + domaine.slug);
        }
    };

    return (
        <TrouverFormationCard
            active={trouverFormation.selectedDomaine?.id === domaine.id}
            onClickCard={handleDomaineClick}
            customClass="min-h-[100px] px-4"
            content={() => <>
                <TfiBag size={25} />
                <span className="font-semibold text-sm text-center">{domaine.libelle}</span>
            </>
            }
        />
    )
}

export default DomaineCardItem
