import React from "react"

import AppMaxWidth from "components/AppMaxWidth/AppMaxWidth"
import SectionTitle from "components/Section/SectionTitle"
// import Domaine from "components/Domaine"
// import { DiplomeChoixCardItem } from "../TrouverFormation/diplomes/DiplomeListChoix"
import LoadingIndicator from "components/Loader/LoadingIndicator"
import RequestError from "components/RequestError/RequestError"
import DomaineCardItem from "components/TrouverFormation/DomaineCardItem"
import EmptyData from "components/EmptyData/EmptyData"
import { useDomaines } from "../TrouverFormation/domaines/useDomaines"

const DomaineDeFormation = () => {
  const { data, isError, isLoading } = useDomaines()

  return (
    <AppMaxWidth>
      <SectionTitle title={"Plus de 500 formations dans différents domaines"} />
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 mt-8">
        {/* {
          [1, 2, 3, 4, 5, 6, 7].map((index) => <Domaine key={index} />)
        } */}
        {
          isLoading ? <LoadingIndicator /> : isError ? <RequestError /> :
            data && data.results.length ?
                data.results.map((domaine, index) => <DomaineCardItem key={index} domaine={domaine} />) :
                <EmptyData message={"Pas encore de domaines"} />
        }
      </div>
    </AppMaxWidth>
  )
}

export default DomaineDeFormation
