import AppMaxWidth from "components/AppMaxWidth/AppMaxWidth";
import React from "react"
import { NavLink } from "react-router-dom";
import { publicRoutes } from "routes/navigation/navigationRoutes/public";
import useReactRouterBreadcrumbs from "use-react-router-breadcrumbs";

const PublicBreadcrumps = ({ description }) => {
    const breadcrumbs = useReactRouterBreadcrumbs(publicRoutes);

    return (
        <div className="h-50">
            <AppMaxWidth>
                <ul className="flex">
                    {
                        breadcrumbs.map(({ match, breadcrumb }, index) => (
                            <NavLink
                                // className="text-white font-semibold"
                                className={(index === breadcrumbs.length - 1 ? "text-white font-medium text-sm" : "text-white font-semibold text-sm")}
                                key={match.pathname}
                                to={match.pathname}
                            >
                                {breadcrumb}
                                <span className="mx-2">{index === breadcrumbs.length - 1 ? "" : ">"}</span>
                            </NavLink>
                        ))
                    }
                </ul>
                { description && <p className="text-xl font-semibold text-white mt-9">{description}</p> }
            </AppMaxWidth>
        </div>


    );
}

export default PublicBreadcrumps
