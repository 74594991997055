/* eslint-disable max-len */
import React, { useState } from "react"

import HeaderItem from "./HeaderItem"
import BlackLogo from "components/Logo/BlackLogo"
import { ECOLES_PATH, FORMATIONS_PATH, LOGIN_PATH, TROUVER_FORMATION_PATH } from "routes/navigation/navigationPaths"
import Button from "components/Button"
import { MdMenu, MdOutlineSchool } from "react-icons/md"
import { Link, useNavigate } from "react-router-dom"
import MobileNavbar from "layouts/navbar/mobile/MobileNavbar"

const Header = () => {
    const navigate = useNavigate()
    // const [openNavbar, setOpenNavbar] = useState(false);
    const [open, setOpen] = useState(false);
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    // <HeaderItem to={""}>Acceuil</HeaderItem>
    //                                 <HeaderItem to={ECOLES_PATH}>Ecoles</HeaderItem>
    //                                 <HeaderItem to={FORMATIONS_PATH}>Formations</HeaderItem>
    const links = [
        {
            path: "",
            label: "Acceuil"
        },
        {
            path: ECOLES_PATH,
            label: "Ecoles"
        },
        {
            path: FORMATIONS_PATH,
            label: "Formations"
        },
    ]
    return (
        <header className="bg-white sticky top-0 z-10 shadow-md">
            <div className="mx-auto max-w-screen-xl px-3">
                <div className="flex h-16 items-center justify-between">
                    <div className="md:flex md:items-center md:gap-12">
                        <BlackLogo />
                    </div>

                    <div className="hidden lg:flex">
                        <nav aria-label="Global">
                            <ul className="flex items-center gap-6 text-sm">
                                <HeaderItem to={"/"}>Acceuil</HeaderItem>
                                <HeaderItem to={ECOLES_PATH}>écoles & Universités</HeaderItem>
                                <HeaderItem to={FORMATIONS_PATH}>Diplômes</HeaderItem>
                            </ul>
                        </nav>
                    </div>

                    <div className="hidden md:flex items-center gap-4">
                        <Button
                            onClick={() => navigate(TROUVER_FORMATION_PATH)}
                            icon={{
                                name: MdOutlineSchool,
                                color: "rgba(17, 17, 167)",
                                size: 25
                            }}
                            className="bg-white text-center font-semibold hover:opacity-70 text-blue flex-auto h-11 shadow-xl px-9"
                            title={"Trouver une formation"}
                        />
                        <div className="sm:flex sm:gap-4">
                            <Link
                                className="rounded-full font-semibold bg-teal-600 px-5 py-2.5 text-sm hover:bg-[#003f96] transition-all bg-blue text-white shadow"
                                to={LOGIN_PATH}
                            >
                                Connexion
                            </Link>
                        </div>

                    </div>
                    <MdMenu onClick={showDrawer} size={25} className="block md:hidden" />
                    <MobileNavbar links={links} onClose={onClose} open={open} >
                        <div className="flex flex-col gap-4">
                            <Button
                                onClick={() => {
                                    onClose();
                                    navigate(TROUVER_FORMATION_PATH)
                                }}
                                icon={{
                                    name: MdOutlineSchool,
                                    color: "rgba(17, 17, 167)",
                                    size: 25
                                }}
                                className="bg-white text-center font-semibold hover:bg-blueSecondary text-blue h-11 drop-shadow-lg px-4 w-full"
                                title={"Trouver une formation"}
                            />
                            <Link
                                className="rounded-full font-semibold bg-teal-600 px-5 py-2.5 text-sm hover:bg-[#003f96] transition-all bg-blue text-white shadow flex-1 text-center"
                                to={LOGIN_PATH}
                            >
                                Connexion
                            </Link>
                        </div>
                    </MobileNavbar>
                </div>
            </div>
        </header>
    )
}

export default Header
