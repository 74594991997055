/* eslint-disable max-len */
import React, { useEffect } from "react"

import Banner from "components/Banner/Banner";
// import Section from "components/Section/Section";
// import SectionTitle from "components/Section/SectionTitle";
// import UnistafSection from "components/UnistafSection/UnistafSection";
// import CardSection from "components/CardSection/CardSection";
// import AppMaxWidth from "components/AppMaxWidth/AppMaxWidth";
import TrouverMaVoie from "components/HomePage/TrouverMaVoie";
import FormationsPlusRecherchees from "components/HomePage/FormationsPlusRecherchees";
import DomaineDeFormation from "./DomaineDeFormation";
import { useTrouverMaFormationeContexte } from "context/TrouverMaFormationeContexteProvider";

const PublicHomePage = () => {
  const { handleReset } = useTrouverMaFormationeContexte()
  useEffect(() => {
    handleReset()
  }, [])
    return (
        <>
            <Banner />
            <DomaineDeFormation />
            <TrouverMaVoie />
            <FormationsPlusRecherchees />
        </>
    )
}

export default PublicHomePage
