/* eslint-disable max-len */
import Button from "components/Button"
import React from "react"
import { HiArrowRight, HiPhone } from "react-icons/hi"

import whiteLogo from "assets/logos/unistaf-white-logo.png"
import { MdEmail } from "react-icons/md"
import Input from "components/Input"

const Footer = () => {
    return (
        <footer className="bg-blueTercary pt-[40px] mt-9">
            <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
                <div
                    style={{ marginTop: "-130px" }}
                    className="flex flex-col flex-wrap items-center gap-10 rounded-lg bg-white p-6 drop-shadow-2xl sm:flex-row sm:justify-between"
                >
                    <strong className="text-xl sm:text-xl flex-1 sm:min-w-max"> Restez informés des mises à jour! </strong>

                    <div className="flex flex-wrap items-center gap-5 flex-1">
                        <div className="flex-1">
                            <input type="text" id="first_name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 px-5 min-w-max"
                                placeholder="isaac@gmail.com" required
                            />
                        </div>
                        <Button
                            title={"S'inscrire"}
                            icon={{
                                name: HiArrowRight,
                                position: "right",
                                color: "#FFF",
                                size: 20
                            }}
                            className="text-white border border-white bg-blue hover:bg-[#003f96] hover:text-white focus:outline-none h-11 px-5"
                        />
                    </div>
                </div>

                <div className="mt-16 grid grid-cols-1 lg:grid-cols-3 gap-8 text-white">
                    <div className="">
                        <div className="text-lg font-medium text-gray-900">
                            <img src={whiteLogo} alt="" />
                        </div>

                        <p>Avec Unifaf, votre passion trouve la formation qu’elle mérite !</p>
                    </div>

                    <div className="">
                        <p className="text-lg font-medium text-gray-900">Coordonnées</p>

                        <ul className="mt-0 space-y-4 text-sm">
                            <li className="flex items-center gap-3 text-lg">
                                <HiPhone /> <span>+221 78 739 33 34</span>
                            </li>

                            <li className="flex items-center gap-3 text-lg">
                                <MdEmail /> <span>contact@unistaf.com</span>
                            </li>
                        </ul>
                    </div>

                    <div className="">
                        <p className="text-lg font-medium text-gray-900">Nous contacter</p>

                        <form>
                            <Input
                                placeholder={"Email"}
                                extraClasse={"bg-inputbg border-blue"}
                            />
                            <textarea rows={5} className="w-full bg-inputbg rounded-2xl mt-3 p-3.5 placeholder:text-sm" placeholder="Message"></textarea>
                            <div className="flex justify-end">
                                <Button title={"Envoyer"} className="bg-white text-black px-5 h-10 mt-2 uppercase" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
