import AppMaxWidth from "components/AppMaxWidth/AppMaxWidth"
import PublicBreadcrumps from "components/Breadcrumps/PublicBreadcrumps"
import Button from "components/Button"
import ListeEcoles from "components/Ecoles/ListeEcoles"
// import Input from "components/Input"
import React from "react"
import { CiLocationOn, CiSearch } from "react-icons/ci"
import useEcoles from "./useEcoles"
import SuspenseBoundary from "components/SuspenseBoundary"
import AnimateOnRender from "components/AnimateRender/AnimateOnRender"
import UniversitesPubliques from "components/Universites/UniversitesPubliques"

const EcolePage = () => {
    const { ecoles, isLoading, isError } = useEcoles()
    return (
        <>
            <div className="bg-blue text-white py-8">
                <PublicBreadcrumps description="Trouver une école" />
            </div>
            <AppMaxWidth className="py-8">
                <AnimateOnRender>
                    <div className="bg-white border border-gray rounded-lg p-6 mb-8">
                        <form className="flex flex-wrap items-end gap-5 relative">
                            <div className="flex-1 min-w-[250px]">
                                <div className="relative">
                                    <input
                                        type="text"
                                        placeholder="Recherche établissement"
                                        className="w-full h-11 pl-10 pr-4 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none"
                                    />
                                    <CiSearch color="#0A65CC" className="absolute left-3 top-1/2 -translate-y-1/2 text-blue w-5 h-5" />
                                </div>
                            </div>

                            <div className="hidden md:block w-[1px] h-11 bg-gray self-end" />

                            <div className="flex-1 min-w-[250px]">
                                <div className="relative">
                                    <input
                                        type="text"
                                        placeholder="Recherche lieu"
                                        className="w-full h-11 pl-10 pr-4 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none"
                                    />
                                    <CiLocationOn color="#0A65CC" className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 w-5 h-5" />
                                </div>
                            </div>
                            <div className="flex items-end">
                                <Button
                                    title="Rechercher"
                                    className="bg-blue text-white px-8 h-11 rounded-full hover:bg-blue-600 transition-colors"
                                />
                            </div>
                        </form>
                    </div>

                    <div className="grid grid-cols-1 gap-8 md:grid-cols-[minmax(0,3fr)_minmax(0,2fr)]">
                        <div>
                            <h2 className="text-xl font-semibold mb-6">Les établissements les plus recherchées</h2>
                            <SuspenseBoundary isLoading={isLoading} isError={isError}>
                                <ListeEcoles ecoles={ecoles.results} />
                            </SuspenseBoundary>
                        </div>

                        <div className="bg-[#F8F8F8] p-4 h-fit rounded-2xl">
                            <h2 className="text-xl font-semibold mb-6">Les universités publiques</h2>
                            <UniversitesPubliques />
                        </div>
                    </div>
                </AnimateOnRender>
            </AppMaxWidth>
        </>
    )
}

export default EcolePage
