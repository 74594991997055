import React, { createContext, useContext, useMemo, useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"
import { setDiplome, setDomaine, setFiliere, setLieuFormation } from "redux/features/trouverFormationSlice"

const TrouverMaFormationeContexte = createContext()

const TrouverMaFormationeContexteProvider = ({ children }) => {
  const { trouverFormation } = useSelector((state) => state)
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [currentIndex, setCurrentIndex] = useState(Number(localStorage.getItem("currentIndex")) ?? 0)

  function setParams(query, value) {
    searchParams.set(`${query}`, value)
    setSearchParams(searchParams)
  }

  const nextStep = () => {
    setCurrentIndex((prev) => prev + 1)
  }

  const previousStep = () => {
    setCurrentIndex((prev) => prev - 1)
  }

  const handleReset = () => {
    setCurrentIndex(0)
    dispatch(setDomaine(null))
    dispatch(setFiliere(null))
    dispatch(setDiplome(null))
    dispatch(setLieuFormation(null))
    setSearchParams({})
  }

  useEffect(() => {
    localStorage.setItem("currentIndex", currentIndex)
  }, [currentIndex])

  const setDomaineChoice = (domaine) => {
    setParams("domaine", domaine.slug)
    dispatch(setDomaine(domaine))
    if (domaine.slug !== trouverFormation.selectedDomaine?.slug) {
      dispatch(setFiliere(null))
      dispatch(setDiplome(null))
      dispatch(setLieuFormation(null))
    }
  }

  const setFiliereChoice = (filiere) => {
    setParams("filiere", filiere.slug)
    dispatch(setFiliere(filiere))
    if (filiere.slug !== trouverFormation.selectedFiliere?.slug) {
      dispatch(setDiplome(null))
      dispatch(setLieuFormation(null))
    }
  }

  const setDiplomeChoice = (diplome) => {
    dispatch(setDiplome(diplome))
    if (diplome.slug !== trouverFormation.selectedDiplome?.slug) {
      dispatch(setLieuFormation(null))
    }
  }

  const setLieuFormationChoice = (lieu) => {
    setParams("lieu", lieu.libelle)
    dispatch(setLieuFormation(lieu))
  }

  const contextValue = useMemo(() => {
    return {
      setDomaineChoice,
      setFiliereChoice,
      setDiplomeChoice,
      setLieuFormationChoice,
      trouverFormation,
      currentIndex,
      nextStep,
      previousStep,
      handleReset
    }
  }, [trouverFormation, currentIndex])

  return (
    <TrouverMaFormationeContexte.Provider value={contextValue}>
      {children}
    </TrouverMaFormationeContexte.Provider>
  )
}

export default TrouverMaFormationeContexteProvider

export const useTrouverMaFormationeContexte = () => {
  const ctx = useContext(TrouverMaFormationeContexte)
  return ctx
}
