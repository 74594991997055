import AppMaxWidth from "components/AppMaxWidth/AppMaxWidth"
import Button from "components/Button"
import { BsArrowLeft, BsArrowRepeat } from "react-icons/bs"
import React, { useEffect, useState } from "react"
import { useTrouverMaFormationeContexte } from "context/TrouverMaFormationeContexteProvider"
import DiplomeListChoix from "./diplomes/DiplomeListChoix"
import FormationsLists from "./formations/FormationsLists"
import DomaineListChoix from "./domaines/DomaineListChoix"
import FiliereListChoix from "./filieres/FiliereListChoix"
import LieuEtudeListChoix from "./lieux/LieuEtudeListChoix"
import useScrollToTop from "hooks/useScrollToTop"


const TrouverFormation = () => {
    const { trouverFormation, currentIndex, previousStep, handleReset } = useTrouverMaFormationeContexte()
    const [progressBar, setProgressBar] = useState(0)
    useScrollToTop()

    const children = [
        <DomaineListChoix key={1} />,
        <FiliereListChoix key={2} />,
        <DiplomeListChoix key={3} />,
        <LieuEtudeListChoix key={4} />,
        <FormationsLists key={5} />
    ]

    useEffect(() => {
        localStorage.setItem("currentIndex", currentIndex)
        setProgressBar((currentIndex / (children.length - 1)) * 100)
    }, [currentIndex, children.length])

    return (
        <div className="bg-[#F9F9F9] py-9">
            <AppMaxWidth>
                {currentIndex === children.length - 1 ? (
                    <div className="p-4 md:px-9">
                        <div className="flex justify-between items-center py-4">
                            <div className="">
                                {
                                    Object.entries(trouverFormation).map((label, index) => {
                                        return !label[1] ? undefined : (label[1] && index > 0) ? <span key={index}> - {label[1].libelle}</span> : <span key={index}>{label[1].libelle}</span>
                                    })
                                }
                            </div>
                            <div className="flex justify-end items-center gap-2">
                                <Button
                                    onClick={previousStep}
                                    disabled={currentIndex === 0}
                                    className="shadow-sm rounded-full text-sm border p-2 px-4 text-blue"
                                    title="Précédent"
                                    icon={{
                                        name: BsArrowLeft,
                                        position: "left",
                                        color: "#0D0DA3"
                                    }}
                                />
                                <Button
                                    onClick={handleReset}
                                    className="shadow-sm rounded-full text-sm border p-2 px-4 text-blue"
                                    title="Recommencer"
                                    icon={{
                                        name: BsArrowRepeat,
                                        position: "left",
                                        color: "#0D0DA3"
                                    }}
                                />
                            </div>
                        </div>
                        {children[currentIndex]}
                    </div>
                ) : (
                    <div className="bg-white rounded-xl border border-[#D9D9D9]">
                        <div className="flex gap-4">
                            {
                                children.map((_, index) => {
                                    return index > 0 && <div
                                        key={index}
                                        style={{ width: progressBar + "%", transition: "all 300ms" }}
                                        className={`h-[6px] ${currentIndex >= index ? "bg-blue" : "bg-gray"} mb-4 rounded-xl flex-1`}
                                    />
                                })
                            }
                        </div>
                        <div className="p-4 md:px-9">
                            <div className="flex justify-end items-center gap-2">
                                <Button
                                    onClick={previousStep}
                                    disabled={currentIndex === 0}
                                    className="shadow-sm rounded-full text-sm border mb-3 p-2 px-4 text-blue"
                                    title="Précédent"
                                    icon={{
                                        name: BsArrowLeft,
                                        position: "left",
                                        color: "#0D0DA3"
                                    }}
                                />
                            </div>
                            {children[currentIndex]}
                        </div>
                    </div>
                )}
            </AppMaxWidth>
        </div>
    )
}

export default TrouverFormation
