import React from "react"

const universites = [
    {
        id: 1,
        nom: "Université Cheikh Anta Diop de Dakar (UCAD)",
        lieu: "Dakar",
        logo: "/logos/ucad.png"
    },
    {
        id: 2,
        nom: "Université Gaston Berger (UGB)",
        lieu: "Saint Louis",
        logo: "/logos/ugb.png"
    },
    {
        id: 3,
        nom: "Université Alioune Diop de Bambey (UADB)",
        lieu: "Bambey",
        logo: "/logos/uadb.png"
    },
    {
        id: 4,
        nom: "Université Iba Der Thiam (UIDT)",
        lieu: "Thiès",
        logo: "/logos/uidt.png"
    },
    {
        id: 5,
        nom: "Université Assane Seck de Ziguinchor (UASZ)",
        lieu: "Ziguinchor",
        logo: "/logos/uasz.png"
    },
    {
        id: 6,
        nom: "Université Amadou Makhtar Mbow (UAM)",
        lieu: "Diamniadio",
        logo: "/logos/uam.png"
    },
    {
        id: 7,
        nom: "Université Numérique Cheikh Hamidou Kane (UN-CHK)",
        lieu: "Sénégal",
        logo: "/logos/unchk.png"
    },
    {
        id: 8,
        nom: "Université Sine Saloum Elhadj Ibrahima Niasse (USSEIN)",
        lieu: "Sine Saloum",
        logo: "/logos/ussein.png"
    }
]

const UniversitesPubliques = () => {
    return (
        <div className="space-y-4">
            {universites.map((universite) => (
                <div
                    key={universite.id}
                    className="bg-white border border-gray p-4 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200 cursor-pointer"
                >
                    <div className="flex items-center gap-4">
                        <div className="w-16 h-16 flex-shrink-0">
                            <img
                                src={universite.logo}
                                alt={universite.nom}
                                className="w-full h-full object-contain rounded-full border border-gray-100"
                                onError={(e) => {
                                    e.target.src = "/logos/default-university.png"
                                }}
                            />
                        </div>
                        <div className="flex-1 min-w-0">
                            <h3 className="font-medium text-gray-900 truncate">
                                {universite.nom}
                            </h3>
                            <p className="text-sm text-gray-500 flex items-center gap-1">
                                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                                </svg>
                                {universite.lieu}
                            </p>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default UniversitesPubliques
