import React from "react"
import { useFormations } from "./useFormations"
import LoadingIndicator from "components/Loader/LoadingIndicator"
import RequestError from "components/RequestError/RequestError"
import { useSelector } from "react-redux"
import DiplomeList from "components/Diplomes/DiplomeList"

const FormationsLists = () => {
  const { trouverFormation } = useSelector((state) => state)
  // console.log({ trouverFormation });
  const { data, isLoading, isError } = useFormations({
    domaine: trouverFormation?.selectedDomaine?.libelle,
    filiere: trouverFormation?.selectedFiliere?.libelle,
    diplome: trouverFormation?.selectedDiplome?.libelle,
    ville: trouverFormation?.selectedLieuFormation?.libelle
  })

  if (isLoading) {
    return <LoadingIndicator />
  }
  if (isError) {
    return <RequestError />
  }

  return (
    <div>
      <h2 className="text-4xl font-semibold my-4">
        Les formations qui correspondent à votre projet ({data.results.length})
      </h2>
      <DiplomeList diplomes={data.results} isImage={true} className="flex-col" />
    </div>
  )
}

export default FormationsLists
