import React from "react"
import { MdOutlineSchool, MdSchool } from "react-icons/md"
import { FaCheck, FaHardHat } from "react-icons/fa";

import AppMaxWidth from "components/AppMaxWidth/AppMaxWidth"
import Button from "components/Button"
import imgPrepare from "assets/imgs/preparez-vous.png"
import { TROUVER_FORMATION_PATH } from "routes/navigation/navigationPaths";
import { useNavigate } from "react-router-dom";
import StateCard from "components/State/StateCard";

const Card = ({ title, description }) => {
    return <div className="bg-white py-4 drop-shadow-md flex items-center gap-4 px-3 rounded-lg mb-4">
        <div className="bg-blue min-w-[40px] h-10 rounded-lg flex items-center justify-center">
            <FaCheck color="white" />
        </div>
        <div>
            <p className="font-bold text-md sm:text-base">{title}</p>
            <div className="flex gap-2">
                <div className="w-[6px] h-[5px] bg-grayD rounded-full mt-2"></div>
                <span className="text-grayD text-[12px] font-medium sm:text-sm">{description}</span>
            </div>
        </div>
    </div>
}

const TrouverMaVoie = () => {
    const navigate = useNavigate()
    return (
        <AppMaxWidth>
            <div className="grid grid-cols-1 lg:grid-cols-2 sm:px-10 gap-20">
                <div className="relative">
                    <div className="absolute left-[-5px] top-5 md:left-[-30px] md:top-10">
                        <StateCard
                            icon={FaHardHat}
                            iconBgColor="bg-blue"
                            borderColor="border-blue"
                            number={500}
                            description="Choix de Formation"
                        />
                    </div>
                    <div className="px-9 md:px-4">
                        <img
                            src={imgPrepare}
                            alt="Projet professionnel"
                            className="w-full"
                        />
                    </div>
                    <div className="absolute right-[-5px] bottom-10 md:right-[-40px] md:bottom-20">
                        <StateCard
                            icon={MdSchool}
                            iconBgColor="bg-orange"
                            borderColor="border-orange"
                            number={500}
                            description="Ecoles"
                            flexDirection="flex-col-reverse"
                        />
                    </div>
                </div>
                <div>
                    <h3
                        className="text-3xl font-semibold text-blackSecondary"
                    >
                        Ton avenir professionnel se construit ici !
                    </h3>
                    <div className="w-24 h-1 bg-blackSecondary my-3"></div>
                    <p className="mt-2 mb-5 tracking-wider leading-7">
                        <span className="font-semibold">Unistaf</span> est la plateforme qui aide les étudiants à trouver l'école
                        parfaite et la formation adaptée à leurs ambitions. Connecte-toi avec ta future école en quelques clics.
                    </p>
                    <div className="my-4">
                        <Card
                            title={"Choix éclairé"}
                            description={"Explore une multitude de formations et découvre celles qui ouvrent les meilleures opportunités pour toi."}
                        />
                        <Card
                            title={"Accès à l'information"}
                            description={"Toutes les infos essentielles pour choisir la formation qui te correspond vraiment !"}
                        />
                    </div>
                    <Button
                        onClick={() => navigate(TROUVER_FORMATION_PATH)}
                        className="bg-blue hover:bg-[#003f96] text-white text-center h-11 px-5 text-md font-semibold"
                        title={"Trouver ma voie"}
                        icon={{
                            name: MdOutlineSchool,
                            color: "#FFF",
                            size: 25
                        }}
                    />
                </div>
            </div>
        </AppMaxWidth>
    )
}

export default TrouverMaVoie
