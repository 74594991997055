/* eslint-disable max-len */
import React from "react"
import { MdOutlineSchool } from "react-icons/md";
import { HiLocationMarker } from "react-icons/hi";
import { twMerge } from "tailwind-merge";

import unistafBlack from "assets/logos/unistaf-black-logo.png"
import BadgeIcon from "components/Badge/BadgeIcon";
import Button from "components/Button";
import Anqsup from "components/Anaqsup";

export const DiplomeSpec = ({ label, desc }) => {
    return <p className="d-flex text-sm">
        <span className="text-tercary text-[15px] font-[600]">{label} : </span> <span className="font-bold">{desc}</span>
    </p>
}

const DiplomeCardItem = ({ diplome, isImage, onClick, extraClasses }) => {
    return (
        <div className={twMerge(["flex flex-col flex-1 gap-5 drop-shadow-sm items-cente bg-white md:flex-row hover:bg-gray-100 px-5"], extraClasses)}>
            {
                isImage && <img
                    className="object-contain w-full rounded-t-lg h-72 md:h-auto md:w-32 md:rounded-none md:rounded-s-lg"
                    src={unistafBlack}
                    alt="Unistaf"
                />
            }
            <div className="flex flex-col flex-1 justify-between py-4 leading-normal">
                <div className="flex items-center gap-3">
                    <h5
                        className="mb-2 text-xl font-bold tracking-tight text-gray-900">
                        {diplome.libelle}
                    </h5>
                    {/* {
                        isImage && <span className="text-tercary font-semibold"> - UNISTAF</span>
                    } */}
                </div>
                <div className="flex flex-col gap-2 py-3 md:flex-row md:gap-x-10 flex-wrap">
                    <DiplomeSpec label={"Domaine"} desc={diplome?.domaine?.libelle} />
                    <DiplomeSpec label={"Formation"} desc={diplome.type} />
                    <DiplomeSpec label={"Accréditation"} desc={diplome.accreditations} />
                </div>
                <div className="flex flex-wrap gap-4 justify-between items-center mt-3">
                    <div className="flex gap-3 flex-wrap">
                        <BadgeIcon
                            className={"bg-blueRgba text-blue text-xs"}
                            icon={{
                                name: MdOutlineSchool,
                                size: 15
                            }}
                            label={diplome?.diplome?.libelle}
                        />
                        <Anqsup />
                        <BadgeIcon
                            className={"bg-blueRgba text-blue text-xs"}
                            icon={{
                                name: HiLocationMarker,
                                size: 15,
                                color: "red"
                            }}
                            label={diplome.lieu}
                        />
                    </div>
                    {
                        onClick && <Button
                            onClick={onClick}
                            className={"border text-sm font-semibold border-blueSecondary text-blue hover:bg-blue hover:text-white py-1 px-6"}
                            title={"Voir plus"} />
                    }
                </div>
            </div>
        </div>
    )
}

export default DiplomeCardItem
