import React, { memo } from "react"
import QuestionTitle from "../../../../components/TrouverFormation/QuestionTitle"
// import GridList from "react-flexible-list"
// import { trouverMaFormation } from "data/trouverMaFormation"
import TrouverFormationCard from "../../../../components/TrouverFormation/TrouverFormationCard"
import { useTrouverMaFormationeContexte } from "context/TrouverMaFormationeContexteProvider"
import { useDiplomes } from "./useDiplomes"
import LoadingIndicator from "components/Loader/LoadingIndicator"
import RequestError from "components/RequestError/RequestError"

export const DiplomeChoixCardItem = ({ diplome }) => {
    const { setDiplomeChoice, trouverFormation } = useTrouverMaFormationeContexte()
    // console.log({ selectedDiplome: trouverFormation.selectedDiplome, diplome });
    return <TrouverFormationCard
        active={trouverFormation.selectedDiplome?.id === diplome.id}
        customClass={"rounded-full text-sm"}
        onClickCard={() => setDiplomeChoice(diplome)}
        content={() => <span className="font-semibold text-center">{diplome.libelle}</span>}
    />
}

const DiplomeListChoix = () => {
    const { diplomes, isError, isLoading } = useDiplomes()

    if (isLoading) {
        return <LoadingIndicator />
    }
    if (isError) {
        return <RequestError />
    }

    return (
        <div>
            <QuestionTitle title={"Quelle diplôme souhaitez vous obtenir ?"} />
            <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-4">
                {diplomes.results.map((diplome, index) => <DiplomeChoixCardItem key={index} diplome={diplome} />)}
            </div>
        </div>
    )
}

export default memo(DiplomeListChoix)
